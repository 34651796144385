<template>
  <base-material-card
    icon="far fa-qrcode"
    title="QR-Code"
    color="primary"
  >
    <p>Der QR-Code kann ausgedruckt und in der Halle aufgehängt werden, damit Zuschauer diesen abscannen und direkt auf die Liveanzeige kommen.</p>
    <v-btn block @click="qr(e)">Download</v-btn>
  </base-material-card>
</template>

<script>
import { usePdf } from '@/views/components/stb_w_2023/plugins/pdf'

export default {
  name: 'qrcode',

  setup (props, context) {
    return {
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
